import React from 'react';
import styled from 'styled-components';
import ProfileImg from '../../assets/ProfileImage.jpg';


const ProfileImageContainer = () => {
    return (
            <ImageContainer> 
                <ProfileImage src={ProfileImg}/>
            </ImageContainer>
    )
}

export default ProfileImageContainer

export const ImageContainer = styled.div`
max-width: 400px;
height: auto;
margin-left: 4%;
transition: .5s ease;
border-radius:50%;
@media (max-width: 768px) {
    margin-left:0;
  }
 
`

export const ProfileImage = styled.img`
display: block;
width: 100%;
min-width:250px;
height: auto;
transition: .5s ease;
border-radius:50%;
backface-visibility: hidden;
border: 2px solid transparent;
&:hover {
    border: 2px solid #FF66FF;
} 
`