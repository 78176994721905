import React from 'react';
import styled from 'styled-components';
import AboutMeText from "./AboutMeText"
import Avatar from "./ProfileImage"

const ProfileContainer = () => {

    return (
        <Container id="profile" >
            <H1>About</H1>
            <InnerContainer>
                <Avatar/>
                <AboutMeText/>
            </InnerContainer>
                
        </Container>
    )
}

export default ProfileContainer


export const Container = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
padding-top:25px;
width: 100%;
height: auto;
min-height:100vh;
overflow-y:hidden;
justify-content: center;
`

export const InnerContainer = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
`

export const H1 = styled.h1`
    font-family: 'Fahkwang', sans-serif;
    font-size: 60px;
    color:white;
    text-align:center;
    transition: 1s ease;
    &:hover {
        color: #00CCFF;
    } 
    } 
    @media (max-width: 768px) {
        font-size: 60px;
      }
`


